<template>
  <div class="req-temp-container">
    <br />

    <vs-card class="mt-6">
      <div slot="header">
        <h3 class="font-medium payment-page-branding">Payment page branding</h3>
      </div>
      <form enctype="multipart/form-data">
        <vs-row>
          <vs-col vs-w="12" vs-lg="12" vs-sm="12">
            <h4 class="mb-4 font-normal">Logo</h4>
            <hr />
          </vs-col>
          <vs-row>
            <div class="mt-4 w-full">
              <vs-row>
                <vs-col vs-w="12" vs-lg="12" vs-sm="12">
                  <h5 class="mb-4 mt-10 font-bold"></h5>
                </vs-col>

                <vs-row>
                  <p class="text-base font-light mb-4">
                    Logo will appear at the top of your payment page.
                  </p>
                  <div class="w-full">
                    <div class="vx-col w-full vs-con-loading__container">
                      <div class="flex upload-img my-5" v-if="image">
                        <!-- Image Container -->
                        <div class="flex">
                          <div class="company-logo-wrap relative">
                            <S3ImageGenerator
                              :is-base64="isBase64(image)"
                              :document="image"
                              :custom-class="'responsive'"
                              :key="image"
                            />
                            <span class="XCircleIcon">
                            <feather-icon
                              icon="XCircleIcon"
                              class="ml-1"
                              @click="clearImage"
                            />
                            </span>
                          </div>
                        </div>
                      </div>

                      <input
                        type="file"
                        name="companyLogo"
                        v-validate="{ size: maxFileSize * 1024 }"
                        class="hidden"
                        ref="uploadImgInput"
                        @change="updateCurrImg"
                        accept="image/png"
                      />
                      <div class="flex upload-img my-5" v-if="!image">
                        <div class="force-btn-width
                          flex flex-col
                          md:flex-row
                          justify-content-start
                          flex-wrap
                          md:flex-nowrap
                          items-center">
                          <vs-button
                            flat
                            v-round
                            class="only-border-btn upicon y-centered-icon-text-btn"
                            size="medium"
                            type="border"
                            @click="$refs.uploadImgInput.click()"
                          >
                            <template>
                              <upload-icon
                                size="1.5x"
                                class="custom-class"
                              ></upload-icon
                            ></template>
                            <u>Upload Image</u></vs-button
                          >

                          <div class="mx-5 my-2 md:my-0">
                            <span class="text-black"
                              >Or</span
                            >
                          </div>
                          <vs-button
                            flat
                            v-round
                            class="only-border-btn upicon y-centered-icon-text-btn"
                            size="medium"
                            type="border"
                            @click="popupActivo = true"
                          >
                            <template>
                              <folder-icon
                                size="1.5x"
                                class="custom-class"
                              ></folder-icon
                            ></template>
                            <u>Choose From Library</u></vs-button
                          >
                        </div>
                      </div>
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('companyLogo')"
                        >{{ errors.first("companyLogo") }}</span
                      >
                    </div>
                  </div>
                </vs-row>
              </vs-row>
            </div>
          </vs-row>
          <vs-row class="my-5">
            <h4 class="mr-5 font-normal">Co-brand logo</h4>

            <vs-switch
              class=""
              v-model="partnerLogoEnabled"
              @change="partnerToggle"
            />
            <vs-col>
              <hr />
            </vs-col>

            <vs-row class="mt-6" v-if="partnerLogoEnabled">
              <p class="text-base font-light mb-4">
                Co-brand logo will appear at the top of the payment page beside
                the company logo.
              </p>
              <div class="w-full">
                <template v-if="partnerimage">
                  <!-- Image Container -->
                  <div class="flex upload-img my-5">
                    <div
                      class="company-logo-wrap relative"
                    >
                      <S3ImageGenerator
                        :is-base64="isBase64(partnerimage)"
                        :document="partnerimage"
                        :custom-class="'responsive'"
                        :key="partnerimage"
                      />
                      <span class="XCircleIcon">
                      <feather-icon
                        icon="XCircleIcon"
                        class="ml-1"
                        @click="clearPartnerImage"
                      />
                      </span>
                    </div>

                  </div>
                </template>
                <input
                  type="file"
                  class="hidden"
                  ref="uploadPartnerImgInput"
                  @change="updateCurrPartnerImg"
                  v-validate="{ size: maxFileSize * 1024 }"
                  name="partnerLogo"
                  accept="image/png"
                />
                <div class="flex upload-img items-center my-5" v-if="!partnerimage">
                  <div class="force-btn-width flex flex-col md:flex-row justify-content-start flex-wrap md:flex-nowrap items-center">
                    <vs-button
                      flat
                      v-round
                      class="only-border-btn upicon y-centered-icon-text-btn"
                      size="medium"
                      type="border"
                      @click="$refs.uploadPartnerImgInput.click()"
                    >
                      <template>
                        <upload-icon size="1.5x" class="custom-class"></upload-icon>
                      </template>
                      <u>Upload Image</u></vs-button
                    >
                    <div class="mx-5 my-2 md:my-0">
                      <span class="text-black">Or</span>
                    </div>
                    <vs-button
                      flat
                      v-round
                      class="only-border-btn upicon custom-class y-centered-icon-text-btn"
                      size="medium"
                      type="border"
                      @click="popupActivePartner = true"
                    >
                      <template>
                        <folder-icon size="1.5x" class="custom-class"></folder-icon>
                      </template>
                      <u>Choose From Library</u></vs-button>
                  </div>
                </div>
              <span class="text-danger text-sm" v-show="errors.has('partnerLogo')">{{ errors.first("partnerLogo") }}</span>
              </div>
            </vs-row>
          </vs-row>
        </vs-row>
        <vs-row>
          <div>
            <vs-row>
              <vs-col vs-w="12" vs-lg="12" vs-sm="12">
                <h4 class="mb-4 mt-10 font-normal">Footer</h4>
                <hr />
                <p class="mt-5 mb-5 text-base font-light">
                  Information entered here will appear at the bottom of the
                  payment page.
                </p>
              </vs-col>
              <vs-col vs-w="4" vs-lg="4" vs-sm="12" class="lg:mr-10">
                <div class="mb-5 mt-5">
                  <label class="label">Business Name</label>
                  <vs-input
                    class="w-full"
                    v-validate="'min:3'"
                    size="large"
                    v-model="companyName"
                    name="companyName"
                  />
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('companyName')"
                    >{{ errors.first("companyName") }}</span
                  >
                </div>
              </vs-col>
              <vs-col vs-w="4" vs-lg="4" vs-sm="12">
                <div class="mb-5 mt-5">
                  <label class="label">ABN</label>
                  <br />
                  <the-mask
                    :class="
                      abn
                        ? 'vs-inputx vs-input--input large'
                        : 'vs-inputx vs-input--input large'
                    "
                    :mask="['###########']"
                    v-model="abn"
                    masked
                    name="abn"
                    ref="abn"
                    v-validate="{ required: false, regex: /^\d{11}$/ }"
                  />
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('abn')"
                    >{{ errors.first("abn") }}</span
                  >
                </div>
              </vs-col>
              <vs-col vs-w="4" vs-lg="4" vs-sm="12" class="lg:mr-10">
                <div class="mb-5 mt-5">
                  <label class="label">Email</label>
                  <vs-input
                    class="w-full"
                    v-validate="'email'"
                    size="large"
                    v-model="companyEmail"
                    name="companyEmail"
                  />
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('companyEmail')"
                    >{{ errors.first("companyEmail") }}</span
                  >
                </div>
              </vs-col>
              <vs-col vs-w="4" vs-lg="4" vs-sm="12">
                <div class="mb-5 mt-5">
                  <label class="label">Phone</label>
                  <br />
                  <the-mask
                    :class="
                      phoneNumber
                        ? 'vs-inputx vs-input--input large'
                        : 'vs-inputx vs-input--input large'
                    "
                    :mask="[checkPhoneNumber]"
                    v-model="phoneNumber"
                    masked
                    name="phoneNumber"
                    ref="phoneNumber"
                    v-validate="'phoneFormat'"
                  />
                  <br />
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('phoneNumber')"
                    >{{ errors.first("phoneNumber") }}</span
                  >
                </div>
              </vs-col>
              <vs-col vs-w="4" vs-lg="4" vs-sm="12" class="lg:mr-10">
                <div class="mb-5 mt-5">
                  <label class="label">Website</label>
                  <vs-input
                    class="w-full"
                    v-validate="'url|urlFormat'"
                    size="large"
                    v-model="website"
                    name="website"
                  />
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('website')"
                    >{{ errors.first("website") }}</span
                  >
                </div>
              </vs-col>
              <vs-col vs-w="4" vs-lg="4" vs-sm="12">
                <div class="mb-5 mt-5">
                  <label class="label">Address</label>
                  <vs-input
                    class="w-full"
                    v-validate="''"
                    size="large"
                    v-model="address"
                    name="address"
                  />
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('address')"
                    >{{ errors.first("address") }}</span
                  >
                </div>
              </vs-col>
            </vs-row>
          </div>
        </vs-row>

        <!-- </vs-row> -->

        <div class="button-section">
          <vs-button
            :disabled="!validateForm"
            class="mr-8"
            size="large"
            @click="save"
            v-round
            >Save</vs-button
          >
          <a class="cancel-btn mid-blue" @click="onCancel"><u>Cancel</u></a>
        </div>

        <!-- popup starts -->
        <div class="centerx">
          <vs-popup
            class="holamundo"
            title="Logo Library"
            :active.sync="popupActivo"
          >
            <vs-row>
              <div
                @click="clickLogo(logo)"
                v-for="logo in logoLibrary"
                :key="logo.logoId"
              >
                <vs-col vs-w="4" vs-lg="4" vs-sm="12">
                  <S3ImageGenerator
                    :is-base64="false"
                    :document="logo.logoImage"
                    :custom-class="'selectLogo'"
                    :key="logo.logoImage"
                  />
                </vs-col>
              </div>
            </vs-row>
          </vs-popup>
        </div>

        <div class="centerx">
          <vs-popup
            class="holamundo"
            title="Logo Library Partner"
            :active.sync="popupActivePartner"
          >
            <vs-row>
              <div
                @click="clickPartnerLogo(logo)"
                v-for="logo in logoLibrary"
                :key="logo.logoId"
              >
                <vs-col vs-w="4" vs-lg="4" vs-sm="12">
                  <S3ImageGenerator
                    :is-base64="false"
                    :document="logo.logoImage"
                    :custom-class="'selectLogo'"
                    :key="logo.logoImage"
                  />
                </vs-col>
              </div>
            </vs-row>
          </vs-popup>
        </div>

        <!-- popup ends -->
      </form>
    </vs-card>
    <leave-popup
      :popup-active="popupActive"
      @handleClick="popActions"
    ></leave-popup>
  </div>
</template>

<script>
import { UploadIcon } from "vue-feather-icons";
import { FolderIcon } from "vue-feather-icons";
import { TheMask } from "vue-the-mask";
import { mapActions } from "vuex";
import LeavePopup from "@/views/components/LeavePopup";
import S3ImageGenerator from "@/views/components/S3ImageGenerator";
import { leavePopUpMixin } from "@/mixins/leave-pop-up";

export default {
  mixins: [leavePopUpMixin],
  components: {
    UploadIcon,
    FolderIcon,
    TheMask,
    LeavePopup,
    S3ImageGenerator,
  },
  data() {
    return {
      popupActivo: false,
      popupActivePartner: false,
      merchantId: "",
      saveDisabled: true,
      serverUrl: process.env.VUE_APP_API_URL,
      customerPortalUrl: process.env.VUE_APP_CUSTOMER_PORTAL_URL,
      imageUrl: `${process.env.VUE_APP_API_URL}uploads/merchantlogo/`,
      portalLink: "",
      defaultHeader: true,
      // companyHeader: {},
      companyName: "",
      abn: "",
      companyEmail: "",
      phoneNumber: "",
      website: "",
      address: "",
      image: "",
      logoLibrary: [],
      companyLogo: "",
      partnerimage: "",
      partnerLogo: "",
      partnerLogoEnabled: false,
      popupActive: false, // determines if the leave popup is to be shown
      nextObj: "", // determines the next route,
      isSaved: false,
      childFormDirty: false,
      uploadPartnerLogo: "",
      maxFileSize: process.env.VUE_APP_FILE_MAX_SIZE_MB,
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },
    checkPhoneNumber(){
      let mask = '#### ### ###';
      if(this.phoneNumber && this.phoneNumber.length > 0){
        let numLength = this.phoneNumber.length;
        let value1 = '';
        let value2 = '';
        if(numLength >= 2){
          value1 = this.phoneNumber.substring(0,2);
          if(value1 === '13'){
            value2 = this.phoneNumber.substring(0,4);
          }
        }
        if(value1 === '02' || value1 === '03' || value1 === '07' || value1 === '08'){
          mask = "## #### ####"
        }else{
          mask = "#### ### ###"
        }

        if(value1 === '13'){
          if(parseInt(value2) >= 1300 || value2 === '130' || value2 === '13 0'){
            mask = "#### ### ###"
          }else{
            mask = "## ## ##"
          }
        }
      }
      return mask;
    }
  },

  mounted() {
    this.merchantId =
      JSON.parse(localStorage.user).userType == "admin"
        ? JSON.parse(localStorage.user).merchantId
        : JSON.parse(localStorage.user).partner.merchantId;
    this.getPortalLink();
    this.getGlobalSetting();
    this.getLogos();
  },
  methods: {
    ...mapActions("merchantGlobalSetting", [
      "fetchSettingsByMerchantId",
      "saveDefaultCompanyHeader",
    ]),
    ...mapActions("logo", ["fetchLogosByMerchantId"]),

    getGlobalSetting() {
      this.fetchSettingsByMerchantId().then((res) => {
        let responseData = res.data.data
        this.companyName = this.$lodashHelper.checkObjectKey(responseData,"companyHeader.companyName","");
        this.abn = this.$lodashHelper.checkObjectKey(responseData,"companyHeader.abn","");
        this.companyEmail = this.$lodashHelper.checkObjectKey(responseData,"companyHeader.companyEmail","");
        this.phoneNumber = this.$lodashHelper.checkObjectKey(responseData,"companyHeader.phone","");
        this.website = this.$lodashHelper.checkObjectKey(responseData,"companyHeader.website","");
        this.address = this.$lodashHelper.checkObjectKey(responseData,"companyHeader.address","");
        this.image = this.$lodashHelper.checkObjectKey(responseData,"companyHeader.companyLogo.logoImage","");
        this.companyLogo = this.$lodashHelper.checkObjectKey(responseData,"companyHeader.companyLogo._id","");
        this.partnerimage = responseData.companyHeader && responseData.companyHeader.partnerLogo
          ? responseData.companyHeader.partnerLogo.logoImage
          : undefined;
        this.partnerLogo = this.$lodashHelper.checkObjectKey(responseData,"companyHeader.partnerLogo._id","");
        this.partnerLogoEnabled = responseData.companyHeader && responseData.companyHeader.partnerLogoEnabled
          ? responseData.companyHeader.partnerLogoEnabled
          : this.partnerLogoEnabled;
      });
    },

    getLogos() {
      this.fetchLogosByMerchantId(this.merchantId).then((res) => {
        this.logoLibrary = res.data.data;
      });
    },

    clickLogo(logo) {
      this.companyLogo = logo._id;
      this.image = logo.logoImage;
      this.popupActivo = false;
    },
    clickPartnerLogo(logo) {
      this.partnerLogo = logo._id;
      this.partnerimage = logo.logoImage;
      this.popupActivePartner = false;
    },

    async save() {
      try {
        const valid = await this.$validator.validate();
        if (!valid) {
          return false;
        }

        const formData = new FormData();
        formData.append("companyLogo", this.uploadLogo);
        formData.append("partnerLogo", this.uploadPartnerLogo);
        formData.set("companyName", this.companyName);
        formData.set("abn", this.abn);
        formData.set("companyEmail", this.companyEmail);
        formData.set("phone", this.phoneNumber);
        formData.set("website", this.website);
        formData.set("address", this.address);
        formData.set("partnerLogoEnabled", this.partnerLogoEnabled);
        if (!this.uploadLogo) {
          formData.set("companyLogo", this.companyLogo);
        }
        if (!this.uploadPartnerLogo && this.partnerLogoEnabled) {
          formData.set("partnerLogo", this.partnerLogo);
        }
        this.$vs.loading();
        this.saveDefaultCompanyHeader(formData).then((res) => {
          this.$vs.loading.close();
          this.isSaved = true;
          this.$router.push({ name: "settings" });
        }).catch((ex) => {
          this.$vs.loading.close();
          this.showMessage("Error", ex.data.message, "danger", "icon-times");
        });
      } catch (ex) {
        
      }
    },

    onCancel(e) {
      e.preventDefault();
      this.saveDisabled = false;
      this.$router.push({ name: "settings" });
    },
    getPartnerId() {
      return JSON.parse(localStorage.getItem("user"))._id;
    },
    copyPortalLinkToClipboard() {
      navigator.clipboard.writeText(this.portalLink).then(() => {
        this.$vs.notify({
          title: "Copy to Clipboard",
          text: "Copied text to clipboard",
          color: "success",
          iconPack: "feather",
          position: "top-right",
          icon: "icon-check-circle",
        });
      });
    },
    getPortalLink() {
      this.portalLink =
        this.customerPortalUrl + this.getPartnerId() + "/customer-login";
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        this.uploadLogo = input.target.files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
          this.image = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    },
    updateCurrPartnerImg(input) {
      if (input.target.files && input.target.files[0]) {
        this.uploadPartnerLogo = input.target.files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
          this.partnerimage = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    },
    partnerToggle() {
      this.childFormDirty = true;
      // this.$refs.headerCollapse.maxHeight = "none !important";
    },
    clearImage() {
      this.companyLogo = null;
      this.image = null;
      this.childFormDirty = true;
      this.$refs.uploadImgInput.value = "";
      if (this.errors.has(`companyLogo`)) {
        this.errors.remove(`companyLogo`);
      }
    },
    clearPartnerImage() {
      this.partnerLogo = null;
      this.partnerimage = null;
      this.childFormDirty = true;
      this.$refs.uploadPartnerImgInput.value = "";
      if (this.errors.has(`partnerLogo`)) {
        this.errors.remove(`partnerLogo`);
      }
    },
    isBase64(key) {
      if ( typeof key !== 'string' ) return false;
      const validation = new RegExp(/data:image/);

      return validation.test(key)
    },
    showMessage(title, message, color, icon) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },
  },
  created() {
    this.$validator.extend("phoneFormat", {
      getMessage: () => "The phone number format is invalid.",
      validate: (value) =>
        value.match(
          /^(^04\(?\d{2}\)?[\s.-]\d{3}[\s.-]\d{3})|(^1300[\s.-]\d{3}[\s.-]\d{3})|(^1800[\s.-]\d{3}[\s.-]\d{3})|(^13[\s.-]\d{2}[\s.-]\d{2}$)|(^02[\s.-]\d{4}[\s.-]\d{4})|(^03[\s.-]\d{4}[\s.-]\d{4})|(^07[\s.-]\d{4}[\s.-]\d{4})|(^08[\s.-]\d{4}[\s.-]\d{4})/
        ) !== null,
    });

    this.$validator.extend("urlFormat", {
      getMessage: () => "The website field is not a valid url.",
      validate: (value) =>
        value.match(
          /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
        ) !== null,
    });
  },
  beforeRouteLeave(to, from, next) {
    //check here
    if ((this.isFormDirty || this.childFormDirty) && !this.isSaved) {
      this.nextObj = next;
      this.popupActive = true;
      localStorage.setItem("redirectTo", to.name || undefined);
    } else {
      next();
    }
  },
  watch: {
    isFormDirty(latest, previous) {
      if (latest) localStorage.setItem("formFieldUpdated", "yes");
    },

    childFormDirty(latest, previous) {
      if (latest) localStorage.setItem("formFieldUpdated", "yes");
    },

    isSaved(latest, previous) {
      if (!latest) localStorage.setItem("formFieldUpdated", "yes");
    }
  }
};
</script>
